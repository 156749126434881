<template>
  <v-card class="text-center">
    <v-card-text>
      <v-avatar size="48px">
        <v-img
          :aspect-ratio="isOnPC ? (4 / 3) : (16 / 9)"
          :src="personnel.photo || require('@/assets/blank-avatar.png')"
          class="align-end white--text"
        >
        </v-img>
      </v-avatar>
      <h5 class="text-h6">{{ personnel.name }}</h5>
      <div v-text="personnel.role" />
    </v-card-text>
  </v-card>
  <!-- <v-card>
    <v-img
      :aspect-ratio="isOnPC ? (4 / 3) : (16 / 9)"
      :src="personnel.photo || require('@/assets/blank-avatar.png')"
      class="align-end white--text"
    >
    </v-img>
    <v-card-title>{{ personnel.name }}</v-card-title>
    <v-card-subtitle>{{ personnel.role }}</v-card-subtitle>
  </v-card> -->
  <!-- <v-sheet elevation="2" class="d-flex align-center pa-3">
    <v-avatar class="mr-3">
      <v-img
      :aspect-ratio="isOnPC ? (4 / 3) : (16 / 9)"
      :src="personnel.photo || require('@/assets/blank-avatar.png')"
      class="align-end white--text"
      />
    </v-avatar>
    <div>
      <h4 class="ma-0 pa-0" v-text="personnel.name" />
      <p class="ma-0 pa-0 text--secondary subtitle-text" v-text="personnel.role" />
    </div>
  </v-sheet> -->
</template>

<script>
export default {
  name: 'PersonnelCard',
  props: {
    personnel: {
      required: true,
      type: Object
    }
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  }
}
</script>
