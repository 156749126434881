<template>
  <div>
    <div class="text-justify">
      <h1 class="text-h4 mb-3">Organisasi</h1>
      <p>
        GKBI dalam melaksanakan tugas panggilannya menganut <span class="font-weight-medium">Sistem Presbiterian Sinodal</span> yang merupakan penggabungan dari <span class="font-weight-medium">Sistem Presbiterian</span> dan <span class="font-weight-medium">Sistem Sinodal</span>.
        <span class="font-weight-medium">Sistem Presbiterian Sinodal</span> yang dianut GKBI adalah keputusan tertinggi berada pada rapat-rapat atau disebut juga persidangan-persidangan, serta hasil keputusan dalam setiap persidangan memandatkan kepada pimpinan yang disebut sinodal untuk menjalankannya.
      </p>
      <p>
        Kekuasaan tertinggi GKBI berada pada Sidang Raya dan Sidang Istimewa yang ditetapkan melalui Tata Dasar dan Tata Laksana GKBI. Unsur kelembagaan tinggi GKBI terdiri atas: Majelis Pertimbangan Gereja (MPG), Badan Pekerja Sinode (BPS), dan Badan Pemeriksa Keuangan Gereja (BPKG).
        Struktur kepengurusan pekerja GKBI terdiri atas tiga tingkatan yakni: Tingkat Sinode disebut Badan Pekerja Sinode (BPS), Tingkat Wilayah disebut Badan Pekerja Wilayah (BPW) dan Tingkat Jemaat disebut Badan Pekerja Jemaat (BPJ).
      </p>
    </div>
    <div>
      <h2 class="text-h5">Struktur Organisasi</h2>
      <v-container v-if="!loading" fluid class="ma-0 pa-0">
        <v-row v-for="level in levels" :key="JSON.stringify(level)" justify="center">
          <v-col cols="6" v-for="personnel in level" :key="JSON.stringify(personnel)">
            <personnel-card :personnel="personnel" />
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else fluid class="ma-0 pa-0 fill-height">
        <v-row justify="center" align="center"><v-progress-circular color="primary" indeterminate /></v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import firebase from '@/plugins/firebase'
import PersonnelCardVue from '../../components/OrgChart/PersonnelCard.vue'

export default {
  components: {
    'personnel-card': PersonnelCardVue
  },

  data () {
    return ({
      loading: false,

      levels: [],
      rawPersonnelData: null
    })
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },

  methods: {
    getPersonnelData () {
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('orgChart/').orderBy('level').get()
          .then(snapshot => { resolve(snapshot.docs.map(doc => doc.data())) })
          .catch(e => reject(e))
      })
    },

    // pivot (personnelData) {
    //   var cache = []
    //   personnelData.forEach(entry => {
    //     // console.log(`${entry.name}, ${entry.level}, ${entry.ordering}`)
    //     if (!cache[entry.level]) { cache[entry.level] = [] }
    //     // console.log(cache)
    //     cache[entry.level][entry.ordering] = { name: entry.name, role: entry.role }
    //   })
    //   return cache
    // }

    pivot (personnelData) {
      var cache = [[], []]
      personnelData.forEach(entry => {
        if (entry.level === 0) { cache[0] = [entry]; return }

        cache[1].push(entry)
      })

      return cache
    }
  },

  async mounted () {
    this.$data.loading = true
    try { this.$data.rawPersonnelData = await this.getPersonnelData() } catch (e) {
      this.$store.commit('toast/setError', 'Gagal mengambil data personil.')
    }
    this.$data.loading = false
    this.$data.levels = this.pivot(this.$data.rawPersonnelData)
  }
}
</script>
